import { RouterOutputs } from "~/utils/api";
import LoadingBar from "~/loaders/LoadingBar";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
export default function ProgressLoading({
  contactType,
  detailsPage,
  latestDepsEvaluationsCounts,
  latestEvaluationsCounts
}: Readonly<{
  contactType: "repo" | "user";
  detailsPage?: boolean;
  parentId?: string;
  trackerName?: string;
  latestDepsEvaluationsCounts: RouterOutputs["evaluation"]["latestEvaluationCountsByTypeAndListId"] | undefined;
  latestEvaluationsCounts: RouterOutputs["evaluation"]["latestEvaluationCountsByTypeAndListId"] | undefined;
}>) {
  const doneTypeEvaluationsCount = latestEvaluationsCounts?.doneEvalCount ?? 0;
  const typeEvaluationsCount = latestEvaluationsCounts?.totalEvalCount ?? 0;
  const doneDepsEvaluationsCount = latestDepsEvaluationsCounts?.doneEvalCount ?? 0;
  const depsEvaluationsCount = latestDepsEvaluationsCounts?.totalEvalCount ?? 0;
  const progressRaw = (doneTypeEvaluationsCount + doneDepsEvaluationsCount) / (typeEvaluationsCount + depsEvaluationsCount) * 100;
  const atZero = typeEvaluationsCount + depsEvaluationsCount === 0;
  const progress = isNaN(progressRaw) ? 0 : progressRaw;
  const zeroHandledProgress = atZero ? 100 : progress;
  if (!typeEvaluationsCount && !depsEvaluationsCount) return null;
  return <div className="z-40 flex flex-col bg-transparent pb-4 text-xs text-gray-500" data-sentry-component="ProgressLoading" data-sentry-source-file="ToastProgress.tsx">
      {detailsPage ? <div>
          Evaluated dependencies: {doneDepsEvaluationsCount} /{" "}
          {depsEvaluationsCount} {contactType}
        </div> : <div className="flex items-center gap-2">
          <div>
            Evaluated {contactType}s: {doneTypeEvaluationsCount} /{" "}
            {typeEvaluationsCount} and dependencies: {doneDepsEvaluationsCount}{" "}
            / {depsEvaluationsCount}{" "}
          </div>
          {progress === 100 && <CheckCircleIcon className="h-4 text-green-600" />}
        </div>}
      <LoadingBar completed={zeroHandledProgress} data-sentry-element="LoadingBar" data-sentry-source-file="ToastProgress.tsx" />
    </div>;
}