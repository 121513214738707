import { useState } from "react";
import ToastProgress from "./ToastProgress";
import { useList } from "~/providers/ListProvider";
import ProgressLoadingContactBatchCreations from "./ProgressLoadingContactBatchCreation";
import { ChevronDownIcon } from "@primer/octicons-react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Button from "~/components/base/Button";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import { api } from "~/utils/api";
import useListParams, { type ListParamsType } from "../SharedTable/hooks/useListParams";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
// Update the type of 'utils' to explicitly define it

export const handleRefetchList = async ({
  activeTeamAccountId,
  listId,
  user,
  repo,
  utils,
  setIsLoading
}: {
  activeTeamAccountId: string;
  listId: string;
  user: ListParamsType;
  repo: ListParamsType;
  utils: ReturnType<typeof api.useUtils>;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  setIsLoading && setIsLoading(true);
  await utils.list.listsList.invalidate({
    teamAccountId: activeTeamAccountId
  });
  await utils.list.listById.invalidate({
    teamAccountId: activeTeamAccountId,
    listId: listId
  });
  await utils.listContact.getContactsByListId.invalidate(user);
  await utils.listContact.getContactsByListId.invalidate(repo);
  await utils.contactBatchCreation.getContactBatchCreationErrorCounts.invalidate({
    teamAccountId: activeTeamAccountId,
    listId: listId
  });
  await utils.contactBatchCreation.latestContactBatchCreationCountsByTypeAndListId.invalidate({
    teamAccountId: activeTeamAccountId,
    listId: listId
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndListId.invalidate({
    listId,
    type: "repo",
    teamAccountId: activeTeamAccountId
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndListId.invalidate({
    listId,
    type: "user",
    teamAccountId: activeTeamAccountId
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndListId.invalidate({
    listId,
    type: "user-dependencies",
    teamAccountId: activeTeamAccountId
  });
  await utils.evaluation.latestEvaluationCountsByTypeAndListId.invalidate({
    listId,
    type: "repo-dependencies",
    teamAccountId: activeTeamAccountId
  });
  await utils.listEvaluation.get.invalidate({
    listId: listId,
    teamAccountId: activeTeamAccountId
  });
  setTimeout(() => {
    setIsLoading && setIsLoading(false);
  }, 200);
};
const Toast = ({
  isDashboard
}: {
  isDashboard?: boolean;
}) => {
  const list = useList();
  const listId = list?.id;
  const [showToast, setShowToast] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const utils = api.useUtils();
  const {
    user,
    repo
  } = useListParams();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const {
    data: latestEvaluationsCountsRepo
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId,
    type: "repo",
    teamAccountId: activeTeamAccount?.id
  }, {
    refetchInterval: 1000 * 60
  });
  const {
    data: latestEvaluationsCountsUser
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId,
    type: "user",
    teamAccountId: activeTeamAccount?.id
  }, {
    refetchInterval: 1000 * 60
  });
  const {
    data: latestUserDepsEvaluationsCounts
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId,
    type: "user-dependencies",
    teamAccountId: activeTeamAccount?.id
  });
  const {
    data: latestRepoDepsEvaluationsCounts
  } = api.evaluation.latestEvaluationCountsByTypeAndListId.useQuery({
    listId,
    type: "repo-dependencies",
    teamAccountId: activeTeamAccount?.id
  });
  const {
    data: contactBatchCreations
  } = api.contactBatchCreation.latestContactBatchCreationCountsByTypeAndListId.useQuery({
    teamAccountId: activeTeamAccount.id,
    listId: listId
  }, {
    refetchInterval: 60 * 1000
  });
  if (!latestEvaluationsCountsRepo?.totalEvalCount && !latestEvaluationsCountsUser?.totalEvalCount && !contactBatchCreations?.user.totalContacts && !contactBatchCreations?.repo.totalContacts) return null;
  return <div className={`${showToast && "w-[540px]"} flex p-3 rounded-t-md bg-gray-100 z-50  fixed right-0 bottom-2`} data-sentry-component="Toast" data-sentry-source-file="index.tsx">
      {showToast ? <>
          <div className="flex-1">
            {isDashboard && <Button disabled={isLoading} className={`mb-2 w-60               
              flex gap-2`} onClick={() => handleRefetchList({
          activeTeamAccountId: activeTeamAccount.id,
          listId: listId,
          user,
          repo,
          utils,
          setIsLoading
        })}>
                Refresh Table/Dashboards <LoadingSpinner show={isLoading} />
              </Button>}

            <ProgressLoadingContactBatchCreations contactBatchCreations={contactBatchCreations} />
            <ToastProgress latestDepsEvaluationsCounts={latestRepoDepsEvaluationsCounts} latestEvaluationsCounts={latestEvaluationsCountsRepo} contactType="repo" />

            <ToastProgress latestDepsEvaluationsCounts={latestUserDepsEvaluationsCounts} latestEvaluationsCounts={latestEvaluationsCountsUser} contactType="user" />
          </div>
          <button className="self-start px-2" onClick={() => setShowToast(false)}>
            <ChevronDownIcon className="w-6 h-" />
          </button>
        </> : <button className="rounded-full " onClick={() => setShowToast(true)}>
          <ChevronUpIcon className="w-8 h-8" />
        </button>}
    </div>;
};
export default Toast;