import type { MouseEventHandler } from "react";
import Button from "./Button";
export default function DiscreetButton({
  children,
  onClick,
  disabled,
  className = ""
}: {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
}) {
  return <Button className={`${className} discreet-btn`} onClick={onClick} disabled={disabled} data-sentry-element="Button" data-sentry-component="DiscreetButton" data-sentry-source-file="DiscreetButton.tsx">
      {children}
    </Button>;
}