import { ReactNode, useContext, useState, createContext } from "react";
type SortOptions = string | undefined;
type SortDirection = "asc" | "desc" | undefined;
export interface SortParams {
  field: SortOptions;
  direction: SortDirection;
}
const SortParamsContext = createContext<null | {
  userSearch: string;
  repoSearch: string;
  userSort: SortParams;
  repoSort: SortParams;
  setUserSort: (sort: SortParams) => void;
  setRepoSort: (sort: SortParams) => void;
}>(null);
export const useSortParams = () => {
  const context = useContext(SortParamsContext);
  if (!context) {
    return {
      userSort: null,
      repoSort: null,
      userSearch: "",
      repoSearch: "",
      setUserSort: () => void 0,
      setRepoSort: () => void 0
    };
  }
  return context;
};
const SortParamsProvider = ({
  children,
  userSearch,
  repoSearch
}: {
  children: ReactNode | ReactNode[];
  userSearch: string;
  repoSearch: string;
}) => {
  const [userSort, setUserSort] = useState<SortParams>({
    field: "activity",
    direction: "desc"
  });
  const [repoSort, setRepoSort] = useState<SortParams>({
    field: "activity",
    direction: "desc"
  });
  const value = {
    userSort,
    setUserSort,
    repoSort,
    setRepoSort,
    userSearch,
    repoSearch
  };
  return <SortParamsContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="SortParamsProvider" data-sentry-source-file="SortParamsProvider.tsx">
      {children}
    </SortParamsContext.Provider>;
};
export default SortParamsProvider;